.btn{
    border-radius: 1.25rem;
    border: 1px solid transparent;
    padding: 0.4rem 1.5rem;
    font-size: .75rem;
    line-height: 1.125;
    font-weight: bold;
}


.btn-swal{
    border-radius: 1.25rem;
    border: 1px solid transparent;
    padding: 0.4rem 1.5rem;
    font-size: .75rem;
    line-height: 1.125;
    color: #fff;
}


.btn-primary {
    background-color: initial;
    border: solid 2px transparent;
    background-color: $verde-secundario;
}

.btn-primary:hover {
    color: #fff;
    background-color: $verde-primario;
    box-shadow: none;
    border: 2px solid $verde-primario;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: $verde-primario;
    box-shadow: none;
    border: 2px solid $verde-primario;
}

.btn-primary:disabled {
    background-color: $verde-secundario;
    border-color: $verde-secundario;
}


.btn-primary-swal {
    background-color: initial;
    border: solid 2px transparent;
    background-color: #EA5151;
}



.btn-outline-primary {
    background-color: initial;
    border: solid 2px transparent;
    background-image: linear-gradient(to right, $cian 0%, $verde 100%);
    background-origin: border-box;
    box-shadow: 2px 1000px 1px #fff inset;
    color: #035192
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: transparent;
    box-shadow: none;
}

.btn-outline-login {
    background-color: Transparent;
    background-repeat:no-repeat;
    border: solid 1px white;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    color: white;
    padding: 12px 56px;
    font-weight: 400;
    font-size: 1rem;

}



.btn-outline-login:hover {
    color: #0c3529;
    background-color: white;
    border-color: transparent;
    box-shadow: none;
}

.btn-secondary {
    color: #fff;
    background-color: $azul;
    border-color: $azul;
}

.btn-secondary:hover {
    color: #fff;
    background-color: $azul-secundario;
    border-color: $azul-secundario;
}

.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: $azul-secundario;
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #276188;
    border-color: #276188
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: $azul;
    border-color: $azul;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: $azul;
}

.btn-outline-secondary {
    background-color: initial;
    border: solid 2px transparent;
    background-image: linear-gradient(to right, $cian 0%, $azul 100%);
    background-origin: border-box;
  /*background-clip: content-box, border-box;*/
    box-shadow: 2px 1000px 1px #fff inset;
    color: #035192
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #5a6169;
    border-color: transparent;
    box-shadow: none;
}

.btn-outline-dark {
    background-color: initial;
    border: solid 2px #606060;
    /* background-image: linear-gradient(to right, $gris-claro 0%, $gris-medium 100%); */
    background-origin: border-box;
  /*background-clip: content-box, border-box;*/
    box-shadow: 2px 1000px 1px #fff inset;
    color: #454545;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #606060;
    border-color: transparent;
    box-shadow: none;
}

.btn-outline-info {
    background-color: initial;
    background-image: none;
    border: solid 2px $azul-secundario;
    color: black;
    padding: 0.4rem 0.8rem;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: $azul-secundario;
    border-color: $azul-secundario;
}

.btn-outline-info-normal {
    background-color: initial;
    background-image: none;
    border: solid 2px $azul-secundario;
}

.btn-outline-info-normal:hover {
    color: #fff;
    background-color: $azul-secundario;
    border-color: $azul-secundario;
}

.btn-outline-danger-normal {
    background-color: initial;
    background-image: none;
    border: solid 2px red;
    color: red;
}

.btn-outline-danger-normal:hover {
    color: #fff;
    background-color: red;
    border-color: red;
}

.btn-outline-success-normal {
    background-color: initial;
    background-image: none;
    border: solid 2px $verde;
    color: $verde;
}

.btn-outline-success-normal:hover {
    color: #fff;
    background-color: $verde;
    border-color: $verde;
}

/**SWITCH**/
.react-switch-bg{
    border: solid 1px $verde-secundario !important;
    background: transparent !important;
}
.react-switch-handle{
    background: $verde-secundario !important;
}
.react-switch-handle[aria-checked="false"]{
    background: gray !important;
}

/**RADIOS**/
.c-checkbox span, .c-radio span {
    width: 20px;
    height: 20px;
    border: solid 1px $verde-secundario;
}
.c-checkbox span, .c-radio span:hover {
    width: 20px;
    height: 20px;
    border: solid 1px $verde-secundario;
}
.c-radio.c-radio-nofont input[type=radio]:checked + span {
    /* background: linear-gradient(to right, $cian 0%, $verde 100%) !important; */
    border-color: transparent!important;
    background-color: $verde-secundario;
}
/*centro*/
.c-radio.c-radio-nofont input[type=radio]:checked + span:before {
    background-color: white;
}
/*centro*/
.c-radio.c-radio-nofont input[type=radio]:checked + span:after {
    background-color: red;
}
.custom-radio .custom-control-input:checked~.custom-control-label:before {
    background-color: #4d6784;
}
/**CHECKBOX**/
.c-checkbox input[type=checkbox]:checked + span {
    background-color: $verde-secundario;
    /* background: linear-gradient(to right, $cian 0%, $verde 100%) !important; */
    border-color: transparent!important;
}


/*DROPDOWN*/
.nav-item-dropdown{
    padding: 3px 8px !important;
}


/*LINK*/
.no-underline:hover {
    text-decoration: none;
}


.buttons-box {
    text-align: center;
}



.btn-tabs {
    color:#fff;
    /* border-radius: 0.50rem; */
    font-size: .75rem;
    border: 1px solid transparent;
    font-weight: bold;
    padding: 0.2rem 0.4rem;
    pointer-events: none;
    /* font-weight: bold; */
}


/* .btn{
    border-radius: 1.25rem;
    border: 1px solid transparent;
    padding: 0.4rem 1.5rem;
    font-size: .75rem;
    line-height: 1.125;
    font-weight: bold;
} */


.btn-tabs:hover {
    color: #fff;
    box-shadow: none;
    
}