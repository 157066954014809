.responsive-table-fix {
    width: 50vw;
}

.table-no-border-fix {
    border-radius: 1rem;
}
.table-no-border-fix .table thead {
    border: 1px solid gray;
    border-radius: 0 0 1rem 1rem
}

.react-bs-table-bordered{
    border: none;
    border-radius: 1rem;
    overflow: hidden;
}
.react-bs-container-header{
    /* background: linear-gradient(to bottom, $cian 0%, $verde 100%); */
    background-color: gray;
    padding: 1px !important;
    border-radius: 1rem 1rem 0 0;
}
.table th{
    border-bottom: 0 !important;
    background: $gris-claro;
}
th:first-child {
    border-top-left-radius: 1rem;
}
th:last-child {
    border-top-right-radius: 1rem;
}

.table td, .table th {
    padding: .15rem .75rem;
    vertical-align: middle;
    border: none;
}
.table-striped tbody tr:nth-of-type(even) {
    background-color: $gris-claro;
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: white;
}

.react-bs-container-body{
    border-left: 1px solid gray;
    border-bottom: 1px solid gray;
    border-right: 1px solid gray;
    border-radius: 0 0 1rem 1rem
}

/*Tablas de Bootstrap*/
tbody{
    border-left: 1px solid $gris-claro;
    border-bottom: 1px solid $gris-claro;
    border-right: 1px solid $gris-claro;
    border-radius: 0 0 1rem 1rem;
    color: $gris;
}


/*Tablas de Datasheet*/
.sheet-container{
    max-height: 305px;
    overflow: scroll;
}
.data-grid-container .data-grid {
    table-layout: fixed;
    border-collapse: collapse;
}
table.data-grid {
    margin: auto;
    width: 100%;
}

.data-grid-container .data-grid .cell > input {
    height: 100%;
}

.table-sm{
    font-size: 10px;
}

.table-responsive td{
    white-space: nowrap;
}

.tabla-con-borde{
    border-left: 1px solid $gris-claro !important;
    border-bottom: 1px solid $gris-claro !important;
    border-right: 1px solid $gris-claro !important;
    border-radius: 0 0 1rem 1rem !important;
}
.tabla-con-borde tbody{
    border: none !important;
    border-radius: 0 0 1rem 1rem !important;
}
.tabla-con-borde td:first-child {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 1rem !important;
}

.tabla-con-borde td:last-child {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 1rem !important;
}
.tabla-adentro .react-bs-container-body tbody tr td {
    color: $negro;
}
