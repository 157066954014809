.card {
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #9cb7aa;
    /* border-radius: .625rem; */
    -webkit-box-shadow: 0 5px 8px #888888;
    box-shadow: 0 3px 2px #888888;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    /*border-radius: .25rem*/
}
.card-empty {
    /* overflow: hidden; */
    border: none;
    border-radius: .625rem;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    background-color: #9cb7aa;
    padding: 1px;
}
.card-tabla {
    border: none;
    /* border-radius: .625rem; */
    /* -webkit-box-shadow: 0 5px 8px #888888; */
    /* box-shadow: 0 5px 8px #888888; */
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: initial;
    /* background-color: #9cb7aa; */
    padding: 1px;
}
.card-tabla .card-content-form{
    background-color: white;
    border-radius: .625rem;
    display: flex;
    justify-content: center;
    padding: 3.5rem;
    background-repeat: no-repeat;
    /* background-attachment: fixed; */
    background-position: bottom right;
    background-size: 12%;
    @media (max-width: $md) {
        padding: 1rem;
    }

}
.card-tabla .card-content{
    background-color: white;
    border-radius: .625rem;
}

.card-empty .card-content{
    background-color: white;
    border-radius: .625rem;
}
.card > hr {
    margin-right: 0;
    margin-left: 0
}

.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem
}

.card-subtitle, .card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link + .card-link {
    margin-left: 1.25rem
}

.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: white;
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header + .list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-top: 1px solid rgba(0, 0, 0, .125)
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-bottom: -.75rem;
    border-bottom: 0
}

.card-header-pills, .card-header-tabs {
    margin-right: -.625rem;
    margin-left: -.625rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img {
    width: 100%;
    border-radius: calc(.25rem - 1px)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck {
    display: flex;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 15px
}

@media (min-width: 576px) {
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px
    }

    .card-deck .card {
        display: flex;
        flex: 1 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px
    }
}

.card-group {
    display: flex;
    flex-direction: column
}

.card-group > .card {
    margin-bottom: 15px
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap
    }

    .card-group > .card {
        flex: 1 0;
        margin-bottom: 0
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width: 576px) {
    .card-columns {
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
        -webkit-column-gap: 1.25rem;
        -moz-column-gap: 1.25rem;
        grid-column-gap: 1.25rem;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion > .card {
    overflow: hidden
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0
}

.accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion > .card .card-header {
    margin-bottom: -1px
}


.card-small {
    -webkit-box-shadow: 0 5px 8px #888888;
    box-shadow: 0 3px 4px #888888;
}

.card-small .card-body, .card-small .card-footer, .card-small .card-header {
    padding: 1rem
}
.fondo-totales h6{
    margin-bottom: 0.3rem !important;
}

.fondo-totales label{
    margin-bottom: 0.3rem !important;
}

.fondo-totales span{
    font-weight: 400;
    line-height: 1.5;
}
/* .fondo-totales{

}
.fondo-totales h3{
    margin-bottom: 0 !important;
}
.fondo-totales h4{
    margin-bottom: 0 !important;
    font-weight: 100 !important;
}


.fondo-totales label{
    margin-bottom: 0 !important;
}
.fondo-totales span{
    margin-bottom: 0 !important;
} */
