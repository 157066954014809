/* NUEVO DISEÑO */
.bg-image {
    /* Image used */
    background-image: url("../../../../../assets/img/fondo-login.png");

    /* Full height */
    height: 100vh;
    
    /* Centering and Scaling */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-content {
    background-color: rgb(12,53,41); /* Fallback color */
    background-color: rgba(12, 53, 41, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 20px;
    text-align: center;
}

input.login-field{
    background-color: red;
}

.login-label{
    display: flex;
    margin-left: 10px;
}

.label-login{
    text-transform: uppercase;
}

.form-control-login {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 1.25rem;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    color:white;
}

.form-control-login:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
    color: white;
}