
.border-left-0 {
    border-left: 0 !important
}


/**PAGINACION**/
.pagination {
    padding-left: 10px;
    padding-right: 10px;
    list-style: none;
    border-radius: .375rem;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    font-size: .6rem;
    border-bottom: 1px;
}

.page-item:first-child {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #0c3529;
}
.page-item:last-child {
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #0c3529;
}
.page-item.active .page-link {
    color: #fff;
    background-color: #0c3529;
    /* background: linear-gradient(to right, $cian 0%, $verde 100%) !important; */
    border-radius: 30%;
    border: 1px solid transparent;
}
.page-item {
    border-radius: 50%;
    margin-left: 0.5px;
    margin-right: 0.5px;
    color: black !important;
}
.page-item.disabled .page-link {
    color: $gris;
    font-weight: bold;
    background-color: #fff;
    border-color: #dfe1e3;
}


/* CSS de bateria de almacenes (Grafica de contenedores) */
.contorno-externo{
    color: #fff;
    background-color: $azul-secundario;
    padding: 1px !important;
    border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;

}
.interior-contorno{
    background: #fff;
    color: $verde-secundario;
    border-radius: 1.25rem 1.25rem 1.25rem 1.25rem;
}
.bateria-almacenes{
    color: #fff;
    // background: linear-gradient(to right, $cian 0%, $verde 100%);
    background-color: $azul-secundario;
    padding: 1px !important;
    border-radius: 1rem 1rem 1rem 1rem;
}

.interior-bateria{
    background: $gris-claro;
    color: $azul;
    border-radius: 1rem 1rem 1rem 1rem;
}
