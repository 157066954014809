.google-maps-marker {
    position: relative;
    z-index: 1;
    width: 250px;
    height: 150px;
}
.google-maps-marker .marker_indicator {
    position: absolute;
    z-index: 3;
    transform: translate(-50%, -100%);
    width: 15px;
    height: 20px;
}
.google-maps-marker .marker_content {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    width: 200px;
    transform: translate(0%, -110%);
}
.marker_indicator:hover {
    width: 21px;
    height: 28px;
}
.marker_indicator:hover + .marker_content {
    /* Aplica el estilo al elemento hermano */
    visibility: visible;
}