.btn-agregar{
    position: absolute;
    top: -65px;
    right: -20px;
}


.alerta {
    padding: 1rem 1rem;
    max-width: 60rem;
    margin: 1rem auto 1rem auto;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 1rem;
    text-align: center;
}
.error_mensaje {
    background-color: var(--blanco);
    border-left: 5px solid #b91c1c;
    color: #b91c1c;
}