/* .rc-tabs-tab{
    width: 20.8rem;
} */

:root {
    --color-pendiente: #4d7c0f;
    --color-revision: #15803d;
    --color-preparacion: #047857;
    --color-progreso: #0f766e;
    --color-validacion: #0e7490;
    --color-finalizado: #1d4ed8;
}

.rc-tabs-tab-active {
    border-top: 1px solid #0c3529 !important;
    border-right: 1px solid #0c3529!important;
    border-left: 1px solid #0c3529 !important;
    /* background-image: linear-gradient(to right, $azul 0%, $cian 100%); */
    background-color: #0c3529;
    /* width: 18rem; */
}
.rc-tabs-ink-bar {
    background-color: $gris-claro !important;
}

.rc-tabs-top .rc-tabs-tab {
    margin: 0;
    padding: 5px 35px 5px 35px;
    color: black !important;
    font-weight: bold;
    border: 1px solid $gris-claro;
    border-bottom: 0 !important;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    

    /* border-top-left-radius:1rem; */

}
.rc-tabs-top .rc-tabs-tab-active {
    color: white !important;
}

.rc-tabs-tab:hover {
    color: black;
}
.rc-tabs-top {
    border-bottom: none;
    color: black !important;
}


.rc-tabs-bottom .rc-tabs-tab {
  padding-left: 30px;
  padding-right: 30px;
}

.rc-tabs-top .rc-tabs-bar{
    border-bottom: 0 !important;
    padding-left: 15px;
}

.rc-tabs-tab:first-child{
    /* border-radius: 1rem 0 0 0; */
    border-top-left-radius: 1rem;
}

.rc-tabs-tab:nth-child(2){
    /* border-radius: 1rem 0 0 0; */
    border-top-left-radius: 1rem;
    background-color: var(--color-pendiente);
}

.rc-tabs-tab:nth-child(3){
    /* border-radius: 1rem 0 0 0; */
    border-top-left-radius: 1rem;
    background-color: var(--color-revision);
}

.rc-tabs-tab:nth-child(4){
    /* border-radius: 1rem 0 0 0; */
    border-top-left-radius: 1rem;
    background-color: var(--color-preparacion);
}

.rc-tabs-tab:nth-child(5){
    /* border-radius: 1rem 0 0 0; */
    border-top-left-radius: 1rem;
    background-color: var(--color-progreso);
}

.rc-tabs-tab:nth-child(6){
    /* border-radius: 1rem 0 0 0; */
    border-top-left-radius: 1rem;
    background-color: var(--color-validacion);
}

.rc-tabs-tab:last-child{
    /* border-radius: 0 1rem 0 0; */
    border-top-right-radius: 1rem;
    background-color: var(--color-finalizado);
}

.rc-tabs.invert-tab .rc-tabs-tab:first-child{
    border-radius:0 0 0 1rem ;
}
.rc-tabs.invert-tab .rc-tabs-tab:last-child{
    border-radius: 0 0 1rem 0 !important;
}
